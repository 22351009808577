export default [
  {
    title: 'Tableau de bord',
    route: 'espace-locataire.dashboard',
    icon: 'HomeIcon',
  },
  {
    title: 'Copropriété',
    icon: 'ListIcon',
    children: [
      {
        title: 'Mes contrats',
        icon: 'DiscIcon',
        children: [
          {
            title: 'Contrats de bail',
            route: 'espace-locataire.contrats',
            color: 'secondary',
            icon: 'DiscIcon',
          },
          {
            title: 'Contrats Achat-vente',
            route: 'espace-locataire.contrats-achat-vente',
            color: 'secondary',
            icon: 'DiscIcon',
          },
        ],
      },
      {
        title: 'Mes factures',
        icon: 'DiscIcon',
        children: [
          {
            title: 'Factures de loyer',
            route: 'espace-locataire.factures',
            color: 'secondary',
            icon: 'DiscIcon',
          },
          {
            title: 'Frais de copropriété',
            route: 'espace-locataire.frais-de-copropriete',
            color: 'secondary',
            icon: 'DiscIcon',
          },
          {
            title: 'Facture de copropriété',
            route: 'espace-locataire.facture-de-copropriete',
            color: 'secondary',
            icon: 'DiscIcon',
          },
        ],
      },
    ],
  },

]
