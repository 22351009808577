/* eslint-disable import/no-unresolved */
<template>
  <layout-vertical>

    <router-view />

    <template #navbar="{ toggleVerticalMenuActive }">
      <navbar :toggle-vertical-menu-active="toggleVerticalMenuActive" />
    </template>

    <template #navMenu>
      <vertical-nav-menu
        v-if="!isNavMenuHidden"
        :is-vertical-menu-active="isVerticalMenuActive"
        :toggle-vertical-menu-active="toggleVerticalMenuActive"
      >
        <template #header="slotProps">
          <slot
            name="vertical-menu-header"
            v-bind="slotProps"
          />
        </template>
      </vertical-nav-menu>
    </template>
    <template #footer>
      <locataire-footer />
    </template>

    <!-- <app-customizer
      v-if="showCustomizer"
      slot="customizer"
    /> -->
  </layout-vertical>
</template>

<script>
import { onUnmounted } from '@vue/composition-api'
import LayoutVertical from '@core/layouts/layout-vertical/LayoutVertical.vue'
// eslint-disable-next-line import/no-cycle
import useAppConfig from '@core/app-config/useAppConfig'
import VerticalNavMenu from './partials/VerticalNavMenu.vue'

// import AppCustomizer from '@core/layouts/components/app-customizer/AppCustomizer.vue'
// import { $themeConfig } from '@themeConfig'
import Navbar from './partials/Navbar.vue'
// eslint-disable-next-line import/no-cycle
import useVerticalLayout from './partials/useVerticalLayout'

// eslint-disable-next-line import/no-unresolved
import LocataireFooter from './partials/LocataireFooter.vue'

export default {
  components: {
    // AppCustomizer,
    LayoutVertical,
    Navbar,
    VerticalNavMenu,
    LocataireFooter,
  },
  data() {
    return {
      // showCustomizer: $themeConfig.layout.customizer,
    }
  },
  setup() {
    const {
      routerTransition, navbarBackgroundColor, navbarType, footerType, isNavMenuHidden,
    } = useAppConfig()

    const {
      isVerticalMenuActive,
      toggleVerticalMenuActive,
      isVerticalMenuCollapsed,
      layoutClasses,
      overlayClasses,
      resizeHandler,
      navbarTypeClass,
      footerTypeClass,
    } = useVerticalLayout(navbarType, footerType)

    // Resize handler
    resizeHandler()
    window.addEventListener('resize', resizeHandler)
    onUnmounted(() => {
      window.removeEventListener('resize', resizeHandler)
    })

    return {
      isVerticalMenuActive,
      toggleVerticalMenuActive,
      isVerticalMenuCollapsed,
      overlayClasses,
      layoutClasses,
      navbarTypeClass,
      footerTypeClass,

      // App Config
      routerTransition,
      navbarBackgroundColor,
      isNavMenuHidden,
    }
  },

}
</script>
