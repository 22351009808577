import axiosClient from '@/helpers/axios'
import { ref } from '@vue/composition-api'
// eslint-disable-next-line import/no-cycle
import useUsers from '@/composables/userService'
import router from '@/router'
import store from '@/store'
import useAlertNotification from '@/composables/notification/useAlert'
import useSweetAlertNotification from '@/composables/notification/useSweetAlert'

export default function useAuth() {
  const { successToast } = useAlertNotification()
  const { successSweetAlert, errorSweetAlert } = useSweetAlertNotification()
  const { user, redirectUser } = useUsers()
  const process = ref(false)
  const success = ref(false)

  const handleLogin = async data => {
    try {
      process.value = true

      const response = await axiosClient.post('/login', data)
      if (response.data.success === true) {
        successToast(response.data.message)
        success.value = true
        process.value = false
        const TOKEN = response.data.data.token
        store.state.authStore.token = TOKEN
        store.state.authStore.authenticated = true
        const userAuthentificate = await axiosClient.get('/user')
        user.value = userAuthentificate.data
        store.commit('setUser', userAuthentificate.data)
        store.commit('initializeRole', response.data.data.role)
        await redirectUser(response.data.data.role)
      }
    } catch (error) {
      process.value = false
      success.value = false
      // eslint-disable-next-line no-prototype-builtins
      if (error.response.data.hasOwnProperty('message')) errorSweetAlert('Oups! Erreur', error.response.data.message)
    }
  }
  const handleUserLogin = async data => {
    try {
      process.value = true

      const response = await axiosClient.post('/user-login', data)
      if (response.data.success === true) {
        successToast(response.data.message)
        success.value = true
        process.value = false
        const TOKEN = response.data.data.token
        store.state.authStore.token = TOKEN
        store.state.authStore.authenticated = true
        const userAuthentificate = await axiosClient.get('/user')
        user.value = userAuthentificate.data
        store.commit('setUser', userAuthentificate.data)
        store.commit('initializeRole', response.data.data.role)
        await redirectUser(response.data.data.role)
      }
    } catch (error) {
      process.value = false
      success.value = false
      // eslint-disable-next-line no-prototype-builtins
      if (error.response.data.hasOwnProperty('message')) errorSweetAlert('Oups! Erreur', error.response.data.message)
    }
  }

  const logout = async () => {
    try {
      await axiosClient.post('/logout')
      store.state.authStore.token = null
      store.state.authStore.role = null
      store.state.authStore.authenticated = false
      store.state.authStore.user = {}
      localStorage.removeItem('vuex')
      localStorage.clear()
      successSweetAlert('Déconnexion', 'Déconnexion effectuée avec succès!')

      await router.push({ name: 'login', replace: true })
    } catch (error) {
      // eslint-disable-next-line no-prototype-builtins
      if (error.hasOwnProperty('response') && error.response.status === 401) {
        store.state.authStore.token = null
        store.state.authStore.authenticated = false
        store.state.authStore.user = {}
        localStorage.removeItem('vuex')
        localStorage.clear()
        errorSweetAlert('Déconnexion', 'Session expirée!')

        await router.push({ name: 'login', replace: true })
      }
    }
  }

  return {
    success,
    process,
    logout,
    handleLogin,
    handleUserLogin,
  }
}
